<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_notification"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_notification" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Registro de notificação
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_notification">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pt-0 pb-0">
        <v-flex class="pa-3" xs12>
          <div class="expande-horizontal column">
            <!-- <modal-send-archive /> -->
            <v-form ref="form">
              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Título
                </span>
                <v-text-field
                  v-model="get_notification.title"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: Assunto"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <div class="expande-horizontal centraliza wrap">
                  <v-flex xs12>
                    <div class="expande-horizontal centraliza"></div>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        dark
                        rounded
                        :outlined="sent_to !== 'all' ? true : false"
                        @click="send_to = 'all'"
                        :color="send_to === 'all' ? 'green' : 'grey'"
                        class="mr-1"
                      >
                        <v-icon v-if="send_to === 'all'"> mdi-check </v-icon>
                        Todos</v-btn
                      >
                      <v-btn
                        dark
                        rounded
                        :outlined="sent_to !== 'um' ? true : false"
                        @click="send_to = 'um'"
                        :color="send_to === 'um' ? 'green' : 'grey'"
                        class="mr-1"
                      >
                        <v-icon v-if="send_to === 'um'"> mdi-check </v-icon>
                        Particular</v-btn
                      >
                    </div>
                  </v-flex>
                </div>
              </v-flex>

              <v-flex v-if="send_to === 'um'" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex v-if="!get_notification._id" class="px-1" xs12>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      Usuário
                    </span>
                    <v-autocomplete
                      v-model="get_notification.user_destiny_id"
                      dense
                      solo
                      flat
                      outlined
                      item-text="name"
                      return-object
                      :items="getUsers"
                      :color="$theme.primary"
                      label="ex: João"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex v-else class="px-1 mb-3" xs12>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      Enviado para o usuário {{ get_notification.user.name }}
                    </span>
                  </v-flex>
                </div>
              </v-flex>
              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Mensagem
                </span>
                <v-textarea
                  v-model="get_notification.message"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: conteúdo da mensagem"
                ></v-textarea>
              </v-flex>
            </v-form>
          </div>
        </v-flex>
      </div>

      <div v-if="!get_notification._id" class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Enviar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  data() {
    return {
      send_to: "all"
    };
  },
  computed: {
    ...mapGetters([
      "get_notification",
      "getUsers",
      "get_modal_view_notification",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_notification",
      "atualizar_notification",
      "listUsers",
      "fecha_modal_view_notification"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_notification._id
          ? this.atualizar_notification()
          : this.criar_notification();
      }
    }
  },
  created() {
    this.listUsers();
  }
};
</script>
