<template>
  <v-flex class="pa-3" style="background: #333;" xs12>
    <v-simple-table style="border: 1px solid #333" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <td class="font-weight-bold">Assunto</td>
            <td class="font-weight-bold">Tipo</td>
            <td class="font-weight-bold">Usuário</td>
            <td class="font-weight-bold">Mensagem</td>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="
              `cursor: pointer; background: ${i % 2 === 0 ? '#f2f2f2' : '#fff'}`
            "
            v-for="(item, i) in get_notifications.docs"
            :key="item.id"
          >
            <td
              class="font-weight-bold"
              @click="abre_modal_view_notification(item)"
            >
              {{ item.title }}
            </td>
            <td
              class="font-weight-bold"
              @click="abre_modal_view_notification(item)"
            >
              {{ item.user_destiny_id ? "particular" : "pública" }}
            </td>
            <td
              class="font-weight-bold"
              @click="abre_modal_view_notification(item)"
            >
              {{ item.user ? item.user.name : "" }}
            </td>
            <td @click="abre_modal_view_notification(item)">
              {{ item.message }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_notifications"])
  },
  methods: {
    ...mapActions(["abre_modal_view_notification", "createConfirmAction"]),
    setMarcado(event, item) {
      console.log("event", event);
      // this.add_notification_marcado(item)
    }
  }
};
</script>
